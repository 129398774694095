<template lang="html">
  <v-row>
    <v-col cols="12">
      <v-model-table model-name="Products" model-title="Products and Services"
        :model-api="['models','Master','Products']"
        :model-api-search="filter"
        model-key="productCode"
        :headers="headers" 
        :initial-data="initialData"
        searchable
        importable exportable
      >
        <template v-slot:modelForm="{data,rules,isCreating}">
          <v-container pa-0 fluid>
            <v-row>
              <v-col cols="6" sm="8" md="9">
                <v-radio-group v-model="data.productType" row  :disabled="!isCreating">
                  <v-radio label="ยา" value="medicine"></v-radio>
                  <v-radio label="เวชภัณฑ์" value="supply"></v-radio>
                  <v-radio label="หัตถการ" value="procedure"></v-radio>
                  <v-radio label="บริการ" value="service"></v-radio>
                  <v-radio label="การตรวจห้องปฏิบัติการ" value="laboratory"></v-radio>
                  <v-radio label="การตรวจทางรังสีวิทยา" value="radiology"></v-radio>
                  <v-radio label="รายการชุด" value="package"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6" sm="4" md="3">
                <v-row>
                  <v-switch v-model="data.isActive" label="เปิดใช้งาน" class="mx-2"></v-switch>
                  <v-switch v-model="data.isHidden" label="ซ่อน"></v-switch>
                </v-row>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field :rules="[rules.require()]" v-model="data.productCode" label="รหัสสินค้า" :disabled="!isCreating"></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field :rules="[rules.require()]" v-model="data.productName" label="ชื่อภาษาไทย"></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field :rules="[rules.require()]" v-model="data.productNameEN" label="ชื่อภาษาอังกฤษ"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="data.productKeyword" label="Keyword"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-master-field :rules="[rules.require()]" v-model="data.category" label="หมวดหมู่สำหรับค้นหา"
                  :groupKey="$store.getters.getGroupKey('productCategory')"
                  :filterText="data.productType"
                  clearable
                  show-code
                ></v-master-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-master-field :rules="[rules.require()]" v-model="data.categoryInsurance" label="หมวดหมู่ประกัน"
                  :groupKey="$store.getters.getGroupKey('productCategoryInsurance')"
                  clearable
                  show-code
                ></v-master-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-master-field :rules="[rules.require()]" v-model="data.categoryCgd" label="หมวดหมู่กรมบัญชีกลาง"
                  :groupKey="$store.getters.getGroupKey('productCategoryCgd')"
                  clearable
                  show-code
                ></v-master-field>
              </v-col>
              <v-col cols="4" md="2">
                <v-text-field :rules="[rules.require(),rules.numeric()]" v-model="data.price1" label="ราคา 1 (เงินสด)" suffix="บาท"></v-text-field>
              </v-col>
              <v-col cols="4" md="2">
                <v-text-field :rules="[rules.numeric()]" v-model="data.price2" label="ราคา 2 (ปกส.)" suffix="บาท"></v-text-field>
              </v-col>
              <v-col cols="4" md="2">
                <v-text-field :rules="[rules.numeric()]" v-model="data.price3" label="ราคา 3 (สปสช.)" suffix="บาท"></v-text-field>
              </v-col>
              <v-col cols="4" md="2">
                <v-text-field :rules="[rules.numeric()]" v-model="data.price4" label="ราคา 4 (ราชการ)" suffix="บาท"></v-text-field>
              </v-col>
              <v-col cols="4" md="2">
                <v-text-field :rules="[rules.numeric()]" v-model="data.price5" label="ราคา 5 (ชาวต่างชาติ)" suffix="บาท"></v-text-field>
              </v-col>
              <v-col cols="4" md="2">
                <v-master-field :rules="[rules.requireIf(data.productType=='medicine' || data.productType=='supply')]" v-model="data.saleUnit" label="หน่วยขาย"
                  :groupKey="$store.getters.getGroupKey('unit')"
                  clearable
                ></v-master-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="data.eclaimCode" label="รหัสเบิก Eclaim"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-master-field v-model="data.eclaimAdpType" label="ชนิดเบิก ADP Eclaim"
                  groupKey="$EclaimAdpType"
                  clearable
                  show-code
                ></v-master-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="data.cgdCode" :rules="[rules.requireIf(data.eclaimCode)]" label="รหัสกรมบัญชีกลาง"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-tabs-group>
                  <template v-slot:tabs>
                    <v-tab key="childProducts">รายการเรียกเก็บอัตโนมัติ</v-tab>
                    <v-tab key="itemizedProducts">รายการส่วนประกอบ</v-tab>
                  </template>
                  <template v-slot:items>
                    <v-tab-item key="childProducts" eager>
                      <v-table-field v-model="data.childProducts" title="รายการเรียกเก็บอัตโนมัติ" :headers="chargeHeaders" importable :insertable="false" show-create-pad auto-item-action>
                        <template v-slot:item.productName="props">
                          <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
                        </template>
                        <template v-slot:item.quantity="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.quantity"
                          > 
                            {{ props.item.quantity }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="props.item.quantity"
                                type="number"
                                label="Quantity"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>
                        <template v-slot:createPad="{data: productData,rules}">
                          <v-container fluid pa-0>
                            <v-row>
                              <v-col cols="8" md="6">
                                <v-master-field :rules="[rules.require()]" v-model="productData.productCode" :custom-api="['models','Master','Products','query']" custom-api-text="productName" custom-api-value="productCode" label="รายการ" show-code></v-master-field>
                              </v-col>
                              <v-col cols="4" md="2">
                                <v-text-field :rules="[rules.require(),rules.numeric()]" label="Qty" v-model="productData.quantity"></v-text-field>
                              </v-col>
                              <v-col cols="6" md="2">
                                <v-text-field :rules="[rules.numeric()]" label="ราคาต่อหน่วย" v-model="productData.overridePrice"></v-text-field>
                              </v-col>
                              <v-col cols="6" md="2">
                                <v-text-field :rules="[rules.numeric()]" label="ส่วนลด" v-model="productData.overrideDiscount"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                      </v-table-field>
                    </v-tab-item>
                    <v-tab-item key="itemizedProducts" eager>
                      <v-table-field v-model="data.itemizedProducts" title="รายการส่วนประกอบ" :headers="itemizedHeaders" importable :insertable="false" show-create-pad auto-item-action>
                        <template v-slot:item.productName="props">
                          <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
                        </template>
                        <template v-slot:item.quantity="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.quantity"
                          > 
                            {{ props.item.quantity }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="props.item.quantity"
                                type="number"
                                label="Quantity"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>
                        <template v-slot:createPad="{data: productData,rules}">
                          <v-container fluid pa-0>
                            <v-row>
                              <v-col cols="8" md="10">
                                <v-master-field :rules="[rules.require()]" v-model="productData.productCode" :custom-api="['models','Master','Products','query']" custom-api-text="productName" custom-api-value="productCode" label="รายการ" show-code></v-master-field>
                              </v-col>
                              <v-col cols="4" md="2">
                                <v-text-field :rules="[rules.require(),rules.numeric()]" label="Qty" v-model="productData.quantity"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                      </v-table-field>
                    </v-tab-item>
                  </template>
                </v-tabs-group>
              </v-col>
              <v-col cols="12" v-if="!!data.productType">
                <span class="headline">ข้อมูลเฉพาะ {{ startCase(data.productType) }}</span>
              </v-col>
              <v-col cols="12" v-if="!!data.productType">
                <component :is="specComponent(data.productType)" v-model="data.specification"></component>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:toolBarItem>
          <v-select
            v-model="filterCriteria.productType"
            :items="[
              {text: 'ยา', value: 'medicine'},
              {text: 'เวชภัณฑ์', value: 'supply'},
              {text: 'หัตถการ', value: 'procedure'},
              {text: 'บริการ', value: 'service'},
              {text: 'การตรวจห้องปฏิบัติการ', value: 'laboratory'},
              {text: 'การตรวจทางรังสีวิทยา', value: 'radiology'},
              {text: 'รายการชุด', value: 'package'},
            ]"
            item-text="text" item-value="value"
            label="ชนิดรายการ"
            clearable
            single-line hide-details
          ></v-select>
          <v-master-field v-model="filterCriteria.category" label="หมวดหมู่สำหรับค้นหา"
            :groupKey="$store.getters.getGroupKey('productCategory')"
            :filterText="filterCriteria.productType"
            single-line hide-details
            clearable
            show-code
            class="px-1"
          ></v-master-field>
        </template>
      </v-model-table>
    </v-col>
  </v-row>
</template>

<script>
import {startCase,isUndefined} from 'lodash'

export default {
  data: ()=>({
    headers: [
      {
        text: 'รหัสสินค้า',
        value: 'productCode',
        class: 'body-2'
      },
      {
        text: 'ชนิดสินค้า',
        value: 'productType',
        class: 'body-2'
      },
      {
        text: 'ชื่อภาษาไทย',
        value: 'productName',
        class: 'body-2'
      },
      {
        text: 'ชื่อภาษาอังกฤษ',
        value: 'productNameEN',
        class: 'body-2'
      },
      {
        text: 'Price1',
        value: 'price1',
        class: 'body-2'
      },
      {
        text: 'Price2',
        value: 'price2',
        class: 'body-2'
      },
      {
        text: 'Price3',
        value: 'price3',
        class: 'body-2'
      },
      {
        text: 'Price4',
        value: 'price4',
        class: 'body-2'
      },
      {
        text: 'Price5',
        value: 'price5',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    chargeHeaders: [
      {
        text: 'รหัสรายการ',
        value: 'productCode',
        class: 'body-2',
      },
      {
        text: 'รายการเรียกเก็บ',
        value: 'productName',
        class: 'body-2',
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2',
      },
      {
        text: 'ราคาต่อหน่วย',
        value: 'overridePrice',
        class: 'body-2',
      },
      {
        text: 'ส่วนลด',
        value: 'overrideDiscount',
        class: 'body-2',
      },
    ],
    itemizedHeaders: [
      {
        text: 'รหัสรายการ',
        value: 'productCode',
        class: 'body-2',
      },
      {
        text: 'รายการประกอบ',
        value: 'productName',
        class: 'body-2',
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2',
      },
    ],
    initialData: {
      productType: 'medicine',
      specification: {},
      childProducts: [],
      itemizedProducts: [],
      isActive: true,
      isHidden: false,
    },
    filterCriteria: {},
  }),
  methods: {
    startCase(text) {
      return startCase(text)
    },
    specComponent(productType) {
      return 'vProduct'+startCase(productType)
    }
  },
  computed: {
    filter() {
      let tmpFilter = []

      if (!isUndefined(this.filterCriteria.productType) && this.filterCriteria.productType) tmpFilter.push(['productType','=',this.filterCriteria.productType])
      if (!isUndefined(this.filterCriteria.category) && this.filterCriteria.category) tmpFilter.push(['category','=',this.filterCriteria.category])

      if (tmpFilter.length > 0) {
        return tmpFilter
      } else {
        return undefined
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
